// let's get the site faster
// $intro-transition  : none;
// $global-transition : none;

@font-face {
  font-family: 'Roboto Flex'; /*a name to be used later*/
  src: url('/assets/fonts/RobotoFlex-VariableFont_GRAD,XOPQ,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf'); /*URL to font*/
}

$global-font-family: 'Roboto Flex', sans-serif;

$masthead-link-color: #b0b0b0;
$footer-background-color: #282D33 !default;
$text-color: #212529;
$link-color: #cb3516;

.initial-content a:link {
  color: $link-color !important; // override
}
// link color for main menu
.nav__items a:link {
  color: $text-color !important; // override
}
// link color for toc menu
.toc__menu a:link {
  color: $text-color !important; // override
}
// link color for author-profile
.author__urls a:link {
  color: $text-color !important; // override
}
body {
    font-family: 'Roboto Flex', sans-serif !important; // override
    font-weight: 100 !important; // override
    font-size: 0.8em !important; // override
  }
.page__content p {
  margin-bottom: 0.75em !important; // override
}
.archive__item-excerpt {
    font-size: 0.8em !important; // override
  }
.masthead {
    background: $footer-background-color !important; // override
    // margin-bottom: 2em !important; // override
  }
.masthead__inner-wrap {
    font-family: 'Roboto Flex', sans-serif !important; // override
    font-weight: 200 !important; // override
    max-width: 100% !important; // override
    padding: 0.55em !important; // override
  }
.greedy-nav {
    // background: $footer-background-color !important; // override
    background: transparent !important; // override
    // padding-left: 50em;
  }
// hidden menu items
// .hidden-links .masthead__menu-item a {
//     color: black;
//     // font-weight: 400;
//     font-size: 1.1em !important; // override;
// }
.hidden-links {
    background: $footer-background-color !important; // override
    color: $text-color !important; // override
    // font-weight: 400;
    // font-size: 1.1em !important; // override;
}
.site-title {
    font-family: 'Roboto Flex', sans-serif !important; // override
    font-size: 1.5em !important; // override
    font-weight: 200 !important; // override
  }
h2 {
  @extend .site-title;
  padding-top: 0.5em !important; // override
  padding-bottom: 0em !important; // override
  margin-bottom: none !important; // override
  clear: inline-start;
}
.headline {
    @extend .site-title;
    font-size: 1.75em !important; // override
    color: $link-color;
    border-bottom: 1px solid $masthead-link-color;
    // margin-top: 0.75em;
  }
.page__title {
  border-bottom: none;  
}  
h1 {
    @extend .headline;
    clear: inline-start;
  }
h3 {
    @extend h2;
    font-size: 1.25em !important; // override
    border-bottom: none;
    margin-top: 0.1em !important; // override
    margin-bottom: 0.1em !important; // override
    padding-bottom: 0.1em !important; // override
    clear: inline-start;
  }
h4  {
  @extend h2;
  font-size: 1em !important; // override
  font-weight: 300 !important; // override
}
.archive__item-teaser img {
    max-height: 300px;
    height: auto !important; // override;
    width: auto !important; // override;
  }
.page__footer {
    background-image: url("/assets/images/img-footer.jpg");
    padding: 50px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: white !important; // override
}
.feature__wrapper {
  margin-bottom: 1em !important; // override
}

// gallary layout
figure > a {
  display: -webkit-inline-box !important; // override
}

// image and text side-by-side - used at dojos.md
.container {
  width:500px;
  height: auto;
}
.imageclass {
  float:left;
  width:450px;
  height:400px;
}
.textclass {
  float:left;
  width:300px;
  height:20px;
}

// Format of butten "Probetraining"
.btn-primary:not(:disabled):not(.disabled), .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #d35339 !important; // override;
  color: white !important; // override;
  padding-left: 15%;
  padding-right: 15%;
  border-color: #b0b0b0;
}
.author__urls {
  display: block;
}

.sidebar {
  opacity: 1 !important; // override;;
}

/* FOOTER aikido-dahmeland.de
------------------------------------------------------------------------- */
.page__footer-follow {
  visibility: collapse;
}
.page__footer-copyright {
  visibility: collapse;
}
footer {
    height: auto;
    font-weight: 500;
}
.footer_1 {
    // background-image: url(../../img/img-footer.jpg);
    padding: 50px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
footer.footer_1 h4 {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 0px;
    border-bottom: 1px solid #c0c0c0;
}
.footer-item {
    font-size: 16px;
    padding: 7px 0px;
}
.img-1em img {
    height: 0.85em !important; // override;
}
.sitemap, .opening, .address {
    padding: 0px 40px;
}
.copyright {
    text-align: center;;
    // padding-top: 40px;
}
.address, .address.footer-item {
    text-align: right;
}
footer.footer_2 {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 500;
    padding: 8px 0px;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
}
.footer_1 a, .footer_1 a:visited, .footer_1 a:active, .footer_1 a:focus,
.footer_2 a, .footer_2 a:visited, .footer_2 a:active, .footer_2 a:focus {
    text-decoration: none;
    outline: none;
}
.footer_1 a:hover {
    text-decoration: none;
}
.facebook {
    margin-top: 5px;
    color: #cb3516;
    font-size: 3em;
}
// -------------

@media (max-device-width: 768px){
  .toc {
    display: none;
  }
}



@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
